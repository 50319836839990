/* eslint-disable react-hooks/exhaustive-deps */
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col, ListGroupItem, ListGroup, Button, Popover, PopoverHeader, PopoverBody, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';

import Icon from '@/components/common/icon';
import api from '@/services/api';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';
import sheetAction from '@/views/manager/sheet-action';

const ActionSheet = ({ id }) => {
  const [field, , helpers] = useField({ name: 'sheetActions' });
  const [fieldPublic, , ] = useField({ name: 'public' });
  const [fieldServer, , ] = useField({ name: 'serverDefault' });
  const [fieldId, ,] = useField({ name: 'id' });
  const [actions, setActions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [completeActions, setCompleteActions] = useState([]);
  const [genericFA, setGenericFA] = useState([]);
  const [isProcessing, setIsProcessing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [displayGenericFa, setDisplayGenericFa] = useState(false);
  const [isAllGenericFAValidated, setIsAllGenericFAValidated] = useState(true);
  const [genericFAType, setGenericFAType] = useState(fieldPublic.value ? 'public' : 'private');
  const [genericFAServer, setGenericFAServer] = useState(fieldServer.value);
  const [loadingGenericFa, setLoadingGenericFa] = useState(true);
  const { submitForm } = useFormikContext();

  const loadSheetAction = async (page) => {
    setIsProcessing([true, false]);

    const response = await getResource('SheetAction').readList(page || currentPage);
    const members = response['hydra:member'];

    const next = response['hydra:view'] ? response['hydra:view']['hydra:next'] : null;
    setHasNext(next !== null && next !== undefined);

    setActions(members);

    const temp = new Array(members.length).fill(false);
    const actionInfo = new Array(members.length).fill({});
    const actions = field.value || [];

    members.forEach((sheetAction, index) => {
      actions.forEach((action) => {
        if (action.sheetAction === sheetAction['@id']) {
          temp[index] = true;
          actionInfo[index] = action;
        }
      });
    });

    setCompleteActions(actionInfo);
    setChecked(temp);
    setIsProcessing([false, false]);
    setCurrentPage(page || currentPage);
  };

  const assignSheetAction = (index, item, itemChecked) => {
    const tmp = [...checked];
    tmp[index] = !itemChecked;
    const actions = field.value || [];
    if (!itemChecked) {
      actions.push({ sheetAction: item['@id'], '@id': null, id: null, '@type': 'SheetActionCommunity' });
      helpers.setValue(actions);
    } else {
      let toDeleteIndex = null;
      actions.forEach((element, index) => {
        if (element.sheetAction === item['@id']) {
          toDeleteIndex = index;
        }
      });

      if (toDeleteIndex >= 0) {
        actions.splice(toDeleteIndex, 1);
      }
    }

    setChecked(tmp);
  };

  const loadPage = () => {
    let min = currentPage - 3;
    min = min < 1 ? 1 : min;

    const components = [];
    for (min; min < currentPage; min++) {
      components.push(
        <Col className="col-1">
          <button className="btn-pagination" onClick={() => loadSheetAction(min)}>
            {min}
          </button>
        </Col>
      );
    }

    return components;
  };

  const loadGenericFa = async (type = null, server = null) => {
    setLoadingGenericFa(true);
    const response = await api.get(`/resources/configurations/generic/sheet_actions/${fieldId.value}?type=${type ? type : ''}&server=${server ? server : ''}`);

    if (response.data) {
      let nbValidated = 0;
      response.data.forEach((element) => {
        if (element.state === 'validated') {
          nbValidated++;
        }
      });

      setIsAllGenericFAValidated(response.data.length === 0);
      setGenericFA(response.data);
      setLoadingGenericFa(false);
    }
  };

  const unSelectGenericFa = (id, index) => {
    const tmpGenericFa = [...genericFA];
    tmpGenericFa[index] = tmpGenericFa[index].state === 'pending' ? 'unselected' : 'pending';
    setGenericFA(tmpGenericFa);
  }

  const validateAllGenericFa = () => {
    const sheetActionsCommunities = [];
    genericFA.forEach((element) => {
      if (element.state === 'validated' || element.state !== 'pending') return;
      element.state = 'validated';
      sheetActionsCommunities.push({
        sheetAction: '/sheet_actions/' + element.id,
        '@id': null,
        id: null,
        '@type': 'SheetActionCommunity'
      });
    });

    helpers.setValue([...field.value, ...sheetActionsCommunities]);
    submitForm();
  };

  useEffect(() => {
    loadSheetAction();
    loadGenericFa(genericFAType, genericFAServer);
  }, []);

  return (
    <Card name="action_sheet">
      {isProcessing[0] ? (
        <Spinner className=" col-centered" color="dark" />
      ) : (
        <div className="crud-list">
          <Row className="my-4">
            <Col>
            {loadingGenericFa && <div>Recherche de F.A génériques... <Spinner color="primary" size="sm" /></div>}
              <div className={`displayer-sheet-actions-generic-header ${genericFA.length > 0 ? 'to-display' : ''}`}>
                <div className="d-flex align-items-center">
                <Button onClick={() => setDisplayGenericFa(!displayGenericFa)} className="d-flex align-items-center">
                  <Icon icon={displayGenericFa ? 'chevron-up' : 'chevron-down'} />
                  <h5 className="ml-3 pl-3"> { genericFA.length > 0 ? `Vous avez ${genericFA.length} F.A générique(s) à pousser ` : `Aucune FA générique à pousser` }  </h5>
                </Button>

                <select value={genericFAType} onChange={(e) => {
                  setGenericFAType(e.target.value);
                  loadGenericFa(e.target.value, genericFAServer);
                }}>
                  <option value="private">Privée</option>
                  <option value="public">Public</option>
                </select>

                <select  value={genericFAServer} onChange={(e) => {
                  setGenericFAServer(e.target.value);
                  loadGenericFa(genericFAType, e.target.value);
                }}>
                  <option value="CH-001">CH-001</option>
                  <option value="FR-001">FR-001</option>
                </select>
                </div>

                <div className="d-flex align-items-center">

                <Button
                  size="sm"
                  className="btn-visualisation mx-2"
                  disabled={isAllGenericFAValidated}
                  onClick={() => setDisplayGenericFa(!displayGenericFa)}
                  >
                    Visualiser
                </Button>
                <Button
                  size="sm"
                  className="btn-validate-all-generic mx-2"
                  disabled={isAllGenericFAValidated}
                  onClick={() => validateAllGenericFa()}
                  >
                  {' '}
                  {isAllGenericFAValidated
                    ? 'Rien à poussées'
                    : 'POUSSER'}
                  .{' '}
                  
                 
                </Button>
                <span
                    id="Information"
                    type="button"
                    color="default"
                    className="rounded-circle p-0 "
                    style={{width: '30px', 'height': '30px'}}
                  >
                    <Icon icon="info-circle" />
                  </span>
                  <UncontrolledTooltip
                    target="Information"
                  >
                     Insertion de toutes les F.A génériques en attente de validation dans la communauté.
                     <br />
                     Dès que la configuration du menu est validée, les F.A génériques seront automatiquement poussées sur mobility.
                  </UncontrolledTooltip>
                  </div>
              </div>
              <div className={`container-fa-generic-list ${displayGenericFa ? 'd-block' : 'd-none'}`}>
                <Table className="thead-light">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nom</th>
                        <th>Sélection à pousser</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                  // On affiche dans une liste, les FA génériques que le controler doit valider pour la communauté.
                  genericFA.length > 0 &&
                    genericFA.map((fa, index) => (
                      <tr key={`${fa.name}_${index}`}>
                        <td>{fa.id}</td>
                        <td >{fa.name}</td>
                        <td>
                          <input type="checkbox" checked={fa.state === 'pending'} onChange={(e) => unSelectGenericFa(fa.id, index)}/>
                        </td>
                      </tr>
                    ))
                }
                {
                  genericFA.length === 0 && <tr><td colSpan="3">Aucune F.A générique à pousser</td></tr>
                }
                    </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <br/>
          <br/>
          <br/>
          <h4 className="my-4">
            Liste de toutes les fiches actions.
            <span
                    id="all-fa"
                    type="button"
                    color="default"
                    className=" rounded-circle p-0 "
                    style={{width: '30px', 'height': '30px'}}
                  >
                    <Icon icon="info-circle" />
                  </span>
                  <UncontrolledTooltip
                    target="all-fa"
                  >
                     Tableau d'origine, il affiche toutes les F.A (même les génériques). Permet aussi la visualisation des tableaux de bords dupliqués.
                  </UncontrolledTooltip>
          </h4>
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                <td>{t('communitiesapp_id')}</td>
                <td>{t('communitiesapp_name')}</td>
                <td>{t('communitiesapp_assigned')}</td>
                <td>Tableaux de bord</td>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(actions) &&
                actions.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    {security.isGranted('ROLE_ACCOUNT_MANAGER') ? (
                      <td key={item}>
                        {isProcessing[1] ? (
                          <Spinner className={`spinner-${index}`} type="grow" color="primary" />
                        ) : (
                          <input
                            disabled={!security.isGranted('ROLE_ACCOUNT_MANAGER')}
                            name={`assign-${index}`}
                            checked={checked[index]}
                            type="checkbox"
                            onClick={() => assignSheetAction(index, item, checked[index])}
                            onChange={() => null}
                          />
                        )}
                      </td>
                    ) : (
                      ''
                    )}
                    <td>
                      {completeActions[index]?.sheetActionsDashboards &&
                        completeActions[index].sheetActionsDashboards.map((shDashboard) => {
                          return (
                            <div key={`dashb_${completeActions[index].id}_${shDashboard.tab}`} className="py-1">
                              <a href={`${shDashboard.dashboard['@id']}`} target="__blank">
                                Voir - {shDashboard.tab}
                              </a>
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row className="w-50 mx-auto">
            <Col className="col-3">
              <button
                className="btn-pagination command"
                disabled={currentPage === 1}
                onClick={() => loadSheetAction(currentPage - 1)}
              >
                {t('communitiesapp_previous')}
              </button>
            </Col>
            <Col className="col">
              <Row>
                {loadPage()}
                <Col className="col-1">
                  <button className="btn-pagination current">{currentPage}</button>
                </Col>
              </Row>
            </Col>
            <Col className="col-3">
              <button
                className="btn-pagination command"
                disabled={!hasNext}
                onClick={() => loadSheetAction(currentPage + 1)}
              >
                {t('communitiesapp_next')}
              </button>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

export default ActionSheet;
