import axios, { CancelToken } from 'axios';
import qs from 'qs';

import environment from '@/services/environment';
import notifications from '@/services/notifications';
import router from '@/services/router';
import security from '@/services/security';
import { t } from '@/services/translator';

export default {
  defaultContentType: 'application/ld+json',
  /**
   * Canceller is used to cancel current requests
   *
   * @private
   *
   * @Type {object}
   */
  _canceller: CancelToken.source(),

  /**
   * Make GET request
   *
   * @param {string}  url
   * @param {?object} param
   * @param {?object} config
   *
   * @return {Promise}
   */
  get(url, param, config) {
    return this.request('GET', url, param, null, config);
  },

  /**
   * Make PATCH request
   *
   * @param {string}  url
   * @param {object}  data
   * @param {?object} param
   * @param {?object} config
   *
   * @return {Promise}
   */
  patch(url, data, param, config) {
    return this.request('PATCH', url, param, data, config);
  },

  /**
   * Make PUT request
   *
   * @param {string}  url
   * @param {object}  data
   * @param {?object} param
   * @param {?object} config
   *
   * @return {Promise}
   */
  put(url, data, param, config) {
    return this.request('PUT', url, param, data, config);
  },

  /**
   * Make POST request
   *
   * @param {string}  url
   * @param {object}  data
   * @param {?object} param
   * @param {?object} config
   *
   * @return {Promise}
   */
  post(url, data, param, config) {
    return this.request('POST', url, param, data, config);
  },

  /**
   * Make DELETE request
   *
   * @param {string}  url
   * @param {?object} config
   *
   * @return {Promise}
   */
  delete(url, config) {
    return this.request('DELETE', url, null, null, config);
  },

  /**
   * Perform an api request
   *
   * @param {string}  method
   * @param {string}  url
   * @param {?object} param
   * @param {object}  data
   * @param {?object} config
   *
   * @return {Promise}
   */
  request(method, url, param, data, config) {
    return new Promise((resolve, reject) => {
      this._checkCredentials().then(() => {
        axios
          .request({
            ...this._buildParams(method, url, param, data),
            ...config
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }

            console.log(error);
            if (!config || false !== config.notify) {
              this._handleError(error, url);
            }

            reject(error);
          });
      });
    });
  },

  /**
   * Cancel all current requests
   */
  cancel() {
    this._canceller.cancel();
    this._canceller = CancelToken.source();
  },

  /**
   * Build base request params
   *
   * @private
   *
   * @param {string}  method
   * @param {string}  url
   * @param {?object} params
   * @param {?object} data
   *
   * @return {object}
   */
  _buildParams(method, url, params, data) {
    const config = {
      method,
      url,
      params,
      data,
      baseURL: environment.get('api_url'),
      headers: {
        'Content-Type': this.defaultContentType
      },
      paramsSerializer: (params) =>
        qs.stringify(params, {
          arrayFormat: 'brackets',
          encode: false
        }),
      cancelToken: this._canceller.token
    };

    if (security.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${security.token}`;
    }

    return config;
  },

  /**
   * Check token and refresh token validity
   * Refresh them if it's still possible
   *
   * @private
   *
   * @return {Promise}
   */
  _checkCredentials() {
    return new Promise((resolve, reject) => {
      if (!security.isAuthenticated() || !security.isTokenExpired()) {
        return resolve();
      }

      if (security.isRefreshTokenExpired()) {
        this._handleExpiredCredentials();
        return reject();
      }

      const request = axios({
        url: environment.get('api_url') + '/refresh_token',
        method: 'POST',
        data: { refresh_token: security.refreshToken },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      request
        .then(({ data }) => {
          security.authenticate(data.token, data.refresh_token);
          resolve();
        })
        .catch(() => {
          // Unable to get refresh token
          this._handleExpiredCredentials();
          reject();
        });
    });
  },

  /**
   * Handle basic api errors
   *
   * @private
   *
   * @param {object} error
   */
  _handleError(error, url) {
    if (url.indexOf('/media') !== -1) {
      return;
    }

    let message = null;

    if (error.response) {
      const { data, status } = error.response;

      switch (status) {
        case 504: {
          message = 'server_not_responding';
          break;
        }
        case 500: {
          message = 'fatal_error';
          break;
        }
        case 404: {
          message = 'resource_not_found';
          break;
        }
        case 403: {
          message = 'access_denied';
          break;
        }
        case 401: {
          if (router.currentUri !== router.path('login')) {
            return this._handleExpiredCredentials();
          }

          break;
        }
        default: {
          message = data && data.message ? data.message : null;
        }
      }
    } else if (!error.response && error.request) {
      message = 'unable_to_contact_api';
    } else if (!error.response && !error.request) {
      throw new Error('The request is malformed');
    }

    message && notifications.error(t('error'), t(message));
  },

  /**
   * Redirect to login if credentials are expired
   *
   * @private
   */
  _handleExpiredCredentials() {
    notifications.warning(t('oops'), 'credentials_expired');
    security.deAuthenticate();
    router.navigate('/login');
  }
};
