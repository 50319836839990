export default {
  diagnostic: {
    name: 'get_users_by_diagnostic_ids_request',
    matchQuery: {
      source: 'response',
      'data.get_user_diagnostics_list': {
        $in: [8]
      }
    },
    query: [
      {
        $project: {
          data: 1
        }
      }
    ]
  },
  community: {
    name: 'get_users_by_community_ids_request',
    matchQuery: { source: 'response' },
    query: [
      {
        $lookup: {
          from: 'user',
          localField: 'data.id',
          foreignField: '_id',
          as: 'user'
        }
      },
      {
        $match: {
          'user.communities': {
            $in: [25]
          }
        }
      },
      {
        $project: {
          data: 1
        }
      }
    ]
  },
  report: {
    name: 'get_users_by_report_id_request',
    matchQuery: { source: 'response' },
    query: [
      {
        $lookup: {
          from: 'user',
          localField: 'data.id',
          foreignField: '_id',
          as: 'user'
        }
      },
      {
        $match: {
          'user.report': 18356
        }
      },
      {
        $project: {
          data: 1
        }
      }
    ]
  }
};
