/* eslint-disable global-require */

export default [
  {
    path: 'import',
    name: 'import',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/import').default,
        title: 'import_title'
      },
      {
        path: '/rh',
        name: 'import_rh',
        context: 'read:list',
        exact: true,
        component: require('@/views/import/import-rh').default,
        title: 'import_rh_title'
      },
      {
        path: '/geometries',
        name: 'import_rh_geometries',
        context: 'read:list',
        exact: true,
        component: require('@/views/import/import-geometries').default,
        title: 'import_rh_geometries'
      }
    ]
  }
];
