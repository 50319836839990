import { useField, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { CSVDownload } from 'react-csv';
// Style
import { Button, FormGroup, Label, Col, Table, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

// Core
import Icon from '@/components/common/icon';
import api from '@/services/api';
import notifications from '@/services/notifications';
//Core
import { t } from '@/services/translator';

// Même dossier
import Site from './information-sheet-site';

const Sites = () => {
  const [sites, , helper] = useField({ name: 'sites' });
  const [id] = useField({ name: 'id' });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const addSite = () => {
    helper.setValue([...sites.value, { name: '', addressInformationSheet: {}, capacity: '' }]);
  };

  const removeSite = (idx) => {
    if (window.confirm('Confirmez-vous la suppression ?')) {
      const newList = [...sites.value];
      newList.splice(idx, 1);
      helper.setValue(newList);
    }
  };

  // a little function to help us with reordering the result
  const reorder = (startIndex, endIndex) => {
    const list = sites.value;
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onPositionChange = (currentIndex, newIndex) => {
    if (newIndex < 0) return;

    const items = reorder(currentIndex, newIndex);

    items.forEach((item) => {
      item.origin = item.id ?? item._id;
      item['@id'] = null;
      item.id = null;
      item._id = null;
    });

    helper.setValue(items);
  };

  const handleCreateCommunityFromSelection = async () => {
    if (window.confirm('Confirmez-vous la création ?')) {
      let newList = [];

      sites.value.forEach((site) => {
        if (site.selected && newList !== null) {
          if (!site.id) {
            newList = null;
            notifications.warning(t('community_create'), t('community_need_to_save_new_sites_selected'));
            return;
          }

          newList.push(site.id);
        }
      });

      if (!newList || newList.length === 0) {
        notifications.warning(t('community_create'), t('community_you_need_to_select_sites'));
        return;
      }

      try {
        setLoading(true);
        notifications.info(t('community_create'), t('community_creation_in_progress'));
        await api.post('/communities/create/from/sites', { community: parseInt(id.value), sites: newList });
        notifications.success(t('community_create'), t('community_creation_success'));
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    }
  };

  const handleExtractAddress = () => {
    let newList = [];

    sites.value.forEach((site) => {
      const address = { ...site.addressInformationSheet };
      const coordinate = `Lat. ${address.coordinate[1]}; Lng. ${address.coordinate[0]}`;
      delete address.coordinate;
      newList.push({
        _id: site.id,
        name: site.name,
        createdAt: site.createdAt,
        ...address,
        coordinate
      });
    });

    setCsvData(newList);
  };

  const handleDeleteSelection = () => {
    if (window.confirm('Confirmez-vous la suppression ?')) {
      const newList = [];

      sites.value.map((site) => {
        if (!site.selected) {
          newList.push(site);
        }
      });

      helper.setValue(newList);
    }
  };

  return (
    <>
      <div class="w-100 d-flex justify-content-end mb-3">
        <Button onClick={handleExtractAddress}>{t('action_extract_addresses')}</Button>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={loading}>
          <DropdownToggle caret color="info" disabled={loading}>
            {t('actions_for_selection')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={handleCreateCommunityFromSelection}>{t('action_create_community')}</DropdownItem>
            <DropdownItem onClick={handleDeleteSelection}>{t('action_delete')}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <Table className="communities-sites-table">
        <thead>
          <tr>
            <th className="w-50px">{t('selections')}</th>
            <th className="w-150px">{t('communitiesapp_site_position')}</th>
            <th className="w-280px">{t('communitiesapp_site_name')}</th>
            <th>{t('communitiesapp_site_address')} </th>
            <th className="w-50px">{t('communitiesapp_site_workforce')}</th>
            <th className="w-50px">{t('communitiesapp_site_active')}</th>
            <th className="w-50px">{t('communitiesapp_site_duplicate')}</th>
            <th className="w-50px">Actions</th>
          </tr>
        </thead>

        <tbody>
          {Array.isArray(sites.value) &&
            sites.value.map((val, idx) => (
              <tr key={`site-${idx}`}>
                <Site
                  idx={idx}
                  numberOfItems={sites.value.length}
                  onDelete={() => removeSite(idx)}
                  onPositionChange={onPositionChange}
                />
              </tr>
            ))}
        </tbody>
      </Table>
      <ErrorMessage name={`sites`}>{(msg) => <div className="informationsheetErrorcolor">{msg}</div>}</ErrorMessage>
      <FormGroup row>
        <Label sm={2} />
        <Col sm={7}>
          <Button
            type="button"
            className="shadow-none informationsheetBtncolor mx-auto mt-3 rounded-pill"
            onClick={addSite}
          >
            <Icon name="plus-circle" className="me-4" />
            {t('communitiesapp_add_site')}
          </Button>
        </Col>
      </FormGroup>
      {csvData && <CSVDownload data={csvData} target="_blank" />}
    </>
  );
};

export default Sites;
