import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_token_exporter',
    icon: <Icon name="download" className="text-danger iconRightMarginMax" />,
    roles: ['ROLE_ACCOUNT_MANAGER'],
    route: 'token_exporter'
  }
];
