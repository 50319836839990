import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import { Button, Row, Col } from 'reactstrap';

import Media from '@/components/common/media';
import Uploader from '@/components/common/upload';
import _Environment from '@/services/environment';
import _getResource from '@/services/resources';
import { t } from '@/services/translator';

const Logo = ({ name, resource, onChange, disabled = false, subFileSaving }) => {
  const [vLogo, , helper] = useField({ name });
  const [media, setMedia] = useState();
  const apiurl = _Environment.get('api_url');

  const UploadComponent = ({ onClick }) => (
    <Button
      type="button"
      tabIndex="-1"
      onClick={onClick}
      className=" shadow-none informationsheetBtncolor rounded-pill "
    >
      {t('communitiesapp_download')}
    </Button>
  );

  const updateLogo = async (media) => {
    // pour changer le useState qui contient l'image
    helper.setValue(media);
    media = media.replace('/media/', '');
    // lecture de l'image (c'est une url)
    const obj = await _getResource('Media').read(media);
    setMedia(obj);
    if (onChange) {
      onChange(media, obj);
    }
  };

  return (
    <Row className="border-bottom py-2">
      <Col className="d-flex align-items-center">
        <Uploader
          resource={resource}
          name={name}
          subFileSaving={subFileSaving}
          component={UploadComponent}
          onChange={updateLogo}
          disabled={disabled}
        />
      </Col>
      <Col className="d-flex align-items-center">{vLogo.value && <Media data={vLogo.value} height="70px" />}</Col>
      <Col className="col-12">
        {media && (
          <>
            <div>{media.originalName}</div>
            <div>{media.path}</div>
          </>
        )}
      </Col>
    </Row>
  );
};

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired
};

export default memo(Logo);
