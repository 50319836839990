import Resource from '@/services/resources/common';

import api from '../api';

export default class SheetActionResource extends Resource {
  constructor(entrypoint = '/sheet_actions', alias = 'SheetAction') {
    super(entrypoint, alias);
  }

  async readList(page = 1) {
    const responses = await api.get(`${this.entrypoint}?page=${page}`);
    return responses.data;
  }
}
