/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Button } from 'reactstrap';

import { Input, TableLangValue, Select, Autocomplete } from '@/components/form';
import { t } from '@/services/translator';

const Dashboard = ({ dataTypes, dataTypeOptions, dashboardType }) => {
  const [itemsField, , helpers] = useField({ name: 'items' });

  const addItem = () => {
    const values = itemsField.value || [];

    values.push({ name: { fr: 'Name' }, colspan: 12, dataType: null, displayName: false });
    helpers.setValue(values);
  };

  const removeItem = (index) => {
    let { value } = itemsField;

    value.splice(index, 1);
    helpers.setValue(value);
  };

  const updateParameters = (type, index) => {
    if (!dataTypes) {
      return [];
    }

    const value = itemsField.value || [];
    value[index].dataType = type;

    helpers.setValue(value);
  };

  const getType = (index) => {
    switch (dashboardType) {
      case 'dashboard':
        return {
          name: `items[${index}].dashboard`,
          tag: 'name',
          display: 'name',
          resource: 'DashboardLayout',
          resourceFunction: 'autocompleteOnContent'
        };
      case 'map':
        return {
          name: `items[${index}].map`,
          tag: 'name',
          display: 'name',
          resource: 'Map',
          resourceFunction: 'autocompleteOnContent',
          placeholder: t('adminapp_search_map')
        };
      default:
        return null;
    }
  };

  console.log('tyype', getType());

  return (
    <Row>
      <Col lg="12">
        <h5>{t('adminapp_title_items')}</h5>
        <Button onClick={addItem} color="primary" size="sm">
          {t('add_dashboard_component')}
        </Button>
      </Col>
      <Col lg="12" className="mt-2">
        <Row>
          {itemsField.value &&
            itemsField.value.map((item, index) => {
              return (
                <Col sm={item.colspan} key={index}>
                  <Input name={`items.${index}.colspan`} type="number" label={t('adminapp_item_colspan')} />
                  <Input name={`items.${index}.videoUrl`} type="text" label={t('adminapp_item_videoUrl')} />
                  <Select
                    options={dataTypeOptions}
                    value={item.dataType}
                    onSelect={(option) => updateParameters(option, index)}
                    label={t('adminapp_graph_type')}
                  />
                  <Input name={`items.${index}.displayName`} type="checkbox" label={t('adminapp_item_displayName')} />
                  <TableLangValue name={`items.${index}.name`} label={t('adminapp_item_names')} />
                  <hr className="mt-4" />
                  <Autocomplete autoload {...getType(index)} />
                  <hr className="mt-4" />
                  <Button className="mt-2" color="danger" onClick={() => removeItem(index)}>
                    {t('adminapp_delete_item')}
                  </Button>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

Dashboard.propTypes = {
  type: PropTypes.string.isRequired
};

export default Dashboard;
