/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col } from 'reactstrap';

import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

const ActionSheet = ({ id }) => {
  const [field, , helpers] = useField({ name: 'id' });
  const [diagnostics, setDiagnostics] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadDiagnostic = async () => {
    const response = await getResource('Diagnostic').list({ community: field.value });
    setDiagnostics(response['hydra:member'] || []);
    setLoading(false);
  };

  useEffect(() => {
    loadDiagnostic();
  }, []);

  return (
    <Card name="action_sheet">
      <div className="crud-list">
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              <td>ID</td>
              <td>NOM (fr)</td>
              <td>ALIAS</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>Chargement... </td>
              </tr>
            ) : (
              diagnostics &&
              diagnostics.map((diagnostic) => {
                return (
                  <tr key={diagnostic.id}>
                    <td>{diagnostic.id}</td>
                    <td>{diagnostic.name.fr ?? ''}</td>
                    <td>{diagnostic.alias}</td>
                    <td>
                      <a href={'/diagnostics/editor?id=' + diagnostic['id']} target="__blank">
                        Voir
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default ActionSheet;
