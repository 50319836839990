/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import View from '@/components/view';
import Api from '@/services/api';
import { t } from '@/services/translator';

const Index = ({ match }) => {
  const [file, setFile] = useState();
  const [type, setType] = useState('point');
  const [tags, setTags] = useState('');
  const [loading, setLoading] = useState(false);

  const upload = async () => {
    setLoading(true);
    Api.defaultContentType = 'multipart/form-data';
    const body = new FormData();
    body.append('file', file);
    body.append('tags', tags);
    try {
      await Api.post(`/imports/geometries/${type}`, body);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <View className="pt-5">
      <Row className="mt-5">
        <Col lg={4}>
          <div>{t('adminapp_form_type')}</div>
          <select defaultValue="point" value={type} onChange={(e) => setType(e.target.value)}>
            <option value="point">MultiPoint</option>
            <option value="line">MultiLine</option>
          </select>
        </Col>
        <Col lg={4}>
          <div>{t('adminapp_form_tags_string')}</div>
          <input type="text" onChange={(e) => setTags(e.target.value)} />
        </Col>
        <Col lg={6} className="mt-2">
          <div>{t('adminapp_file')}</div>
          <Input type="file" onChange={(e) => setFile(e.target.files[0])} accept="text/geojson" />
        </Col>

        <Col sm="12" className="mt-5">
          <Button type="button" onClick={upload} disabled={loading}>
            {t('admin_app_import_file')} {loading ? ' ....loading' : ''}
          </Button>
        </Col>
      </Row>
    </View>
  );
};
export default Index;
