import React, { useEffect, useState } from 'react';
import { FormGroup, Row, Col, Label, Input, ListGroup, ListGroupItem, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import _Api from '@/services/api';
import _Security from '@/services/security';
import { t } from '@/services/translator';

const Communication = ({ community }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadCssFiles = async (e) => {
    setLoading(true);

    const form = new FormData();
    console.log(e.target.files);
    form.append('file', e.target.files[0]);

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${_Security.token}`
    };

    try {
      const response = await _Api.post(`/communities/media/${community}/css`, form, null, { headers });

      const copyList = [...list];
      if (!copyList.includes(response.data['filename'])) {
        copyList.push(response.data['filename']);
        setList(copyList);
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const deleteFile = async (filename) => {
    setLoading(true);
    try {
      await _Api.delete(`/communities/media/${community}/css?filename=${filename}`);

      const copyList = [...list];
      const index = copyList.indexOf(filename);
      copyList.splice(index, 1);
      setList(copyList);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getList = async () => {
    setLoading(true);
    try {
      const response = await _Api.get(`/communities/media/${community}/css`);
      setList(response.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Row className="m-0 mb-1">
        <Col className="col-12 p-2 px-5">
          <h5>{t('communityapp_style_css')}</h5>
          <p className="bg-light mb-2">{t('communityapp_changes_automatically_save_file')}</p>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_css_file_to_update_or_create')} :</Label>
            <Col sm={3}>
              <Input type="file" onChange={uploadCssFiles} disabled={loading} />
            </Col>
            {loading && t('communityapp_loading_file')}
          </FormGroup>
        </Col>
        <Col className="col-12">
          <ListGroup>
            {Array.isArray(list) &&
              list.map((element) => (
                <ListGroupItem className="d-flex justify-content-between">
                  <span>{element}</span>
                  <Button onClick={() => deleteFile(element)} className="btn-danger" disabled={loading}>
                    <Icon name="trash" />
                  </Button>
                </ListGroupItem>
              ))}
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default Communication;
