import { useField, useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormGroup, Table, Button, Row, Col, Spinner, Input } from 'reactstrap';

import Icon from '@/components/common/icon';
import _Form from '@/components/form';
import _Api from '@/services/api';
import { t } from '@/services/translator';

const { Error } = _Form;

/**
 * On va jouer entre
 */
const ManagersTable = () => {
  const [idField] = useField({ name: 'id' });
  const [userToAdd, setUserToAdd] = useState('');
  const [managers, setManagers] = useState([]);
  const { submitForm } = useFormikContext();
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    setLoading(true);
    let values = [...managers];

    if (!values || !Array.isArray(values)) {
      values = [];
    }

    // on va rechercher dans liste
    let data = await _Api.get(`/users/public?username=${userToAdd}`);
    let resp = data.data;
    // si aucun element on le créé
    if (resp && resp['hydra:totalItems'] === 0) {
      resp = {
        user: null,
        username: userToAdd,
        email: userToAdd
      };
    } else {
      // eslint-disable-next-line prefer-destructuring
      resp = resp['hydra:member'][0];
    }

    if (!resp) {
      setLoading(false);

      return;
    }

    if (!resp.roles) {
      resp.roles = [];
    }

    if (resp.roles.indexOf('ROLE_MANAGER') === -1) {
      resp.roles.push('ROLE_MANAGER');
    }

    values.push({ user: resp.id,...resp, create: true });

    const response = await _Api.post('/communities/update/managers', { community: idField.value, copils: values });
    setManagers(response.data);

    setUserToAdd('');
    setLoading(false);
  };

  /**
   * Permet d'activer le calcul pour ce manager.
   */
  const handleCheckboxToCalculate = async (index) => {
    const values = [...managers];
    values[index].toCalculate = !values[index].toCalculate;
    setLoading(true);
    const response = await _Api.post('/communities/update/managers', { community: idField.value, copils: values });
    setManagers(response.data);
    setLoading(false);
  };

  const handleCheckboxToReferent = async (index) => {
    const values = [...managers];

    values[index].referent = !values[index].referent;

    setLoading(true);
    const response = await _Api.post('/communities/update/managers', { community: idField.value, copils: values });
    setManagers(response.data);
    setLoading(false);
  };

  const handleRemovePartner = async (index) => {
    const deletePartner = window.confirm('Confirmez-vous la suppression ?');
    if (deletePartner) {
      const values = [...managers];
      values[index].delete = true;
      setLoading(true);
      const response = await _Api.post('/communities/update/managers', { community: idField.value, copils: values });
      setManagers(response.data);
      setLoading(false);
    }
  };

  const loadManagers = async () => {
    const body = await _Api.get(`/communities/${idField.value}/managers`);
    setManagers(body.data);
  };

  useEffect(() => {
    loadManagers();
  }, []);

  return (
    <FormGroup>
      <Row className="my-2">
        <Col lg="6" md="8" sm="8">
          <Input
            type="text"
            value={userToAdd}
            placeholder="username"
            onChange={(e) => setUserToAdd(e.target.value)}
            disabled={loading}
            label={null}
          />
        </Col>
        <Col lg="6" md="8" sm="8">
          <Button size="sm" type="button" color="info" onClick={() => handleAdd()} disabled={loading}>
            {loading ? <Spinner color="primary" size="sm" /> : t('communitiesapp_add_manager')}
          </Button>
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>{t('manager')}</th>
            <th>{t('toCalculate')}</th>
            <th>{t('isReferent')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(managers) &&
            managers.map((manager, index) => {
              if (!manager) {
                return null;
              }

              return (
                <tr key={index}>
                  <td>
                    <a href={`/users/${manager.user?.id}`}>{manager.email}</a>
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      checked={manager.toCalculate}
                      onChange={() => handleCheckboxToCalculate(index)}
                      disabled={loading}
                    />
                  </td>
                  <td>
                    <Input
                      type="checkbox"
                      checked={manager.referent}
                      onChange={() => handleCheckboxToReferent(index)}
                      disabled={loading}
                    />
                  </td>
                  <td>
                    <Button size="sm" color="danger" onClick={() => handleRemovePartner(index)} disabled={loading}>
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Error name="managers" />
    </FormGroup>
  );
};

export default ManagersTable;
