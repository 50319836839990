import Api from '@/services/api';
import Resource from '@/services/resources/common';

export default class Language extends Resource {
  constructor(entrypoint = '/information_sheets', alias = 'InformationSheet') {
    super(entrypoint, alias);
  }
  create = async (data) => {
    const responses = await Api.post(this.entrypoint, data);

    return responses.data;
  };
}
