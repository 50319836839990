import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';

import _Form from '@/components/form';
import api from '@/services/api';
import getResource from '@/services/resources';

const { Autocomplete, Error, Label } = _Form;

const ServerDefaultField = ({ name }) => {
  const [serverValue, setServerValue] = useState();

  const [field, ...helper] = useField({ name });
  const { value } = field;

  const handChange = async (valueChanged) => {
    const response = await api.get(valueChanged);
    helper[1].setValue(response.data.key);
  };

  const updateAutocomplete = async (key) => {
    const response = await getResource('Server').list({ key });
    setServerValue(response['hydra:member'][0] || null);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    updateAutocomplete(value);
  }, [value]);

  return (
    <FormGroup>
      <Label name={name}>{name}</Label>
      <Autocomplete
        value={serverValue}
        onChange={(optionValue) => handChange(optionValue)}
        clearable
        resource="Server"
        display="name"
        tag="key"
        name="server-1"
      />
      <Error name={name} />
    </FormGroup>
  );
};

ServerDefaultField.propTypes = {
  name: PropTypes.string.isRequired
};

export default ServerDefaultField;
