import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, FormGroup, Label } from 'reactstrap';

import { Autocomplete } from '@/components/common/autocomplete';
import api from '@/services/api';

const Calculations = ({ index, values, setFieldValue }) => {
  const [calculations, setCalculations] = useState([]);
  const [filteredCalculations, setFilteredCalcultations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selection, setSelection] = useState([]);
  const loadReportCalculations = async (URI) => {
    const response = await api.get('/reports/calculations/' + URI.replace('/report_patterns/', '') + '/list');

    if (response.data) {
      setCalculations(response.data);
      setFilteredCalcultations(response.data);
    }
  };

  const handleAddCalculation = (calc) => {
    if (selection.find((c) => c.alias === calc.alias)) {
      return;
    }

    const tmpSelection = [...selection, calc];
    setSelection(tmpSelection);

    setFieldValue(
      'calculations',
      tmpSelection.map((c) => '/calculations/' + c._id)
    );
  };

  const removeElement = (alias) => {
    setSelection(selection.filter((c) => c.alias !== alias));
  };

  const filterOnCalcs = (terms) => {
    const filtered = [];
    calculations.filter((calc) => {
      if (calc.alias.includes(terms)) {
        filtered.push(calc);
      }
    });

    setFilteredCalcultations(filtered);
  };

  const loadCalculations = async (calculations) => {
    const calcs = [];

    for (const calc of calculations) {
      const response = await api.get(calc);
      calcs.push(response.data);
    }

    setSelection(calcs);
    setLoading(false);
  };

  useEffect(() => {
    if (!values.calculations) {
      setLoading(false);
      return;
    }

    loadCalculations(values.calculations);
  }, []);

  return (
    <div className="calculations-card mt-4">
      <Label sm={5}>Calculs : Rattacher des calculs à cette FA</Label>
      <FormGroup row className="mt-4">
        <Col sm={12}>
          <p>Liste des calculs activés sur la fiche action.</p>
          <div className="bg-light" style={{ minHeight: '50px', width: '100%' }}>
            <table className="table striped">
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selection.map((calc, index) => (
                  <tr key={index}>
                    <td>{calc.alias}</td>
                    <td>{calc.type}</td>
                    <td>
                      <Button color="danger" onClick={() => removeElement(calc.alias)}>
                        X
                      </Button>
                    </td>
                  </tr>
                ))}
                {!loading && selection.length === 0 && (
                  <tr>
                    <td>Aucune données</td>
                  </tr>
                )}
                {loading && (
                  <tr>
                    <td>Chargement...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col className="mt-5">
          Recherchez les calculs dont vous avez besoin.
          <ul>
            <li>1. Recherchez le report dont vous voulez voir les calculs</li>
            <li>2. Cliquez sur le nom du report pour afficher les calculs</li>
            <li>3. Cliquez sur "Ajouter" à la ligne du calcul pour l'ajouter à la fiche action</li>
          </ul>
        </Col>
        <Col sm={12} className="d-flex ">
          <p className="p-0 m-0 me-3">Report</p>
          <Autocomplete
            resource="ReportPattern"
            placeholder="Rechercher un report pattern"
            tag="name"
            display="name"
            onChange={(opt) => loadReportCalculations(opt)}
          />
        </Col>
        <Col sm={12}>
          <div className="bg-light" style={{ minHeight: '50px', width: '100%' }}>
            <div className="d-flex">
              <Button
                size="sm"
                color="info"
                className="rounded m-0"
                disabled={filteredCalculations.length === 0}
                onClick={() => {
                  setSelection(filteredCalculations);
                  setFieldValue(
                    'calculations',
                    filteredCalculations.map((c) => '/calculations/' + c._id)
                  );
                }}
              >
                Ajouter tous les calculs
              </Button>
              <input
                placeholder="Rechercher par mot clé dans la liste ci-dessous: ex: velo"
                style={{ minWidth: '450px', marginLeft: '10px', borderRadius: '50px', borderColor: '#ccc' }}
                onChange={(e) => filterOnCalcs(e.target.value)}
              />
            </div>
            <table className="table striped">
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCalculations.map((calc, index) => (
                  <tr key={index}>
                    <td>{calc.alias}</td>
                    <td>{calc.type}</td>
                    <td>
                      <Button type="button" onClick={() => handleAddCalculation(calc)}>
                        Ajouter
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </FormGroup>
    </div>
  );
};

Calculations.propTypes = {
  values: PropTypes.array,
  setValues: () => {}
};

export default Calculations;
