import { useField, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
// Style
import { Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import _Form from '@/components/form';

// Même dossier
import AddressApi from '../address';

const { Input, Checkbox } = _Form;

const Enterprisesite = ({ idx, numberOfItems, onDelete, onPositionChange }) => {
  const [fieldAddress, , helpersAddress] = useField({ name: `sites[${idx}].addressInformationSheet` });

  const onChangeAddress = (newState) => {
    helpersAddress.setValue(newState);
  };

  const options = [];

  for (let i = 0; i < numberOfItems; i++) {
    options.push(
      <option value={i} selected={i === idx}>
        {i + 1}
      </option>
    );
  }

  return (
    <>
      <td className="w-50px d-flex text-center">
        <Checkbox className="" label={null} name={`sites[${idx}].selected`} />
      </td>
      <td className="w-150px">
        <div className="w-100 px-3 d-flex justify-content-between">
          <select style={{ display: 'inline' }} onChange={(e) => onPositionChange(idx, e.target.value)}>
            {options}
          </select>
          <div className="btn-position-container ms-2" style={{ display: 'inline' }}>
            <Button
              className="btn-icon-position btn-sm"
              onClick={() => onPositionChange(idx, idx - 1)}
              disabled={idx - 1 === -1}
            >
              <Icon name="arrow-up" />
            </Button>
            <Button
              className="btn-icon-position btn-sm"
              onClick={() => onPositionChange(idx, idx + 1)}
              disabled={idx + 1 === numberOfItems}
            >
              <Icon name="arrow-down" />
            </Button>
          </div>
        </div>
      </td>
      <td>
        <Input label={null} name={`sites[${idx}].name`} />
        <ErrorMessage name={`sites[${idx}].name`}>
          {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
        </ErrorMessage>
      </td>
      <td className="min-width-150 address-site">
        <AddressApi
          value={fieldAddress.value}
          onChange={onChangeAddress}
          errorName={`sites[${idx}].addressInformationSheet`}
          col="12"
        />
        <p className="address-site-txt">{fieldAddress.value?.address}</p>
      </td>
      <td>
        <Input type="number" className="number-no-arrow" label={null} name={`sites[${idx}].capacity`} />
        <ErrorMessage name={`sites[${idx}].capacity`}>
          {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
        </ErrorMessage>
      </td>
      <td>
        <Checkbox className="" label={null} name={`sites[${idx}].active`} />
      </td>
      <td>
        <Checkbox className="" label={null} name={`sites[${idx}].duplicate`} />
      </td>
      <td>
        <Button type="button" color="danger" outline size="sm" className="border-0" onClick={onDelete}>
          <Icon name="trash" />
        </Button>
      </td>
    </>
  );
};

Enterprisesite.propTypes = {
  idx: PropTypes.number,
  siteState: PropTypes.array,
  handleSiteChange: PropTypes.func
};

export default Enterprisesite;
