import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Input, Row, Col, Spinner } from 'reactstrap';

import { Autocomplete as ClassicAutocomplete } from '@/components/common/autocomplete';
import Icon from '@/components/common/icon';
import api from '@/services/api';
import notifications from '@/services/notifications';
import { t } from '@/services/translator';

const DuplicateCalculations = ({ calculations, type, tags }) => {
  const [modal, setModal] = useState(false);
  const [modalErrors, setModalErrors] = useState(false); // Modal errors

  const [communitySelected, setCommunitySelected] = useState(null);
  const [tagsSelected, setTagsSelected] = useState(null);
  const [selected, setSelected] = useState({});
  const [isBtnSelected, setIsBtnSelected] = useState(false);
  const [visibleCalc, setVisibleCalc] = useState(calculations);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(calculations);

  const toggle = () => setModal(!modal);
  const toggleErrorsModal = () => setModalErrors(!modalErrors);

  const selectAll = (defaultValue, reinitializeCalcs) => {
    if (!calculations) {
      return null;
    }

    let isChecked = !isBtnSelected;
    let calcs = visibleCalc;
    if (defaultValue !== null && defaultValue !== undefined) {
      isChecked = defaultValue;
    }

    if (reinitializeCalcs) {
      calcs = calculations;
    }

    let selectedAll = {};
    calcs.forEach((calc) => {
      selectedAll[calc.id] = isChecked;
    });

    setSelected(selectedAll);
    setIsBtnSelected(isChecked);
    setVisibleCalc(calcs);
  };

  const selectItem = (id, isChecked) => {
    setIsBtnSelected(false);
    setSelected({ ...selected, [id]: isChecked });
  };

  const getTag = (tagsIds, calc) => {
    if (!tagsIds || !tags) return tagsIds;

    let tagsNames = [];

    tags.forEach((objTag) => {
      tagsIds.forEach((tagId) => {
        if (objTag['value'] === tagId) {
          tagsNames.push(objTag.label);
        }
      });
    });

    return tagsNames;
  };

  const filterByTags = (tagsFiltered) => {
    if (!visibleCalc || !tagsFiltered) {
      setTagsSelected(tagsFiltered);
      selectAll(true, true);

      return;
    }

    let selection = [];
    // On récupère les calcs filtrés
    const tmpFiltered = calculations.filter((calc) => {
      let isFound = false;
      if (calc.tags) {
        tagsFiltered.forEach((tag) => {
          if (calc.tags.includes(tag.value)) {
            isFound = true;
            // On met en mode sélecitonné seulement ceux filtrer
            selection[calc.id] = true;
          }
        });
      }

      return isFound;
    });

    setSelected(selection);
    setVisibleCalc(tmpFiltered);
    setIsBtnSelected(false);
    setTagsSelected(tagsFiltered);
  };

  const postCalculationsCopies = async (forceCopy = false) => {
    if (!selected) {
      return;
    }

    let calcsSelected = Object.keys(selected).filter((key) => {
      return selected[key];
    });

    if (!selected || !communitySelected || Object.keys(selected).length === 0 || communitySelected.length === 0) {
      notifications.error(t('error'), t('error_no_calcs_or_communties_selected'));
      return;
    }

    setLoading(true);

    try {
      await api.post('/reports/duplicate/calculations', {
        communities: communitySelected.map((option) => option.value.id),
        type: type['name'] ?? type,
        calculations: calcsSelected.map((key) => parseInt(key)),
        forceCopy
      });

      setModalErrors(false);
      setModal(false);
      setErrors(null);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.message) {
          notifications.error(t('error'), t(data.message));
        }

        if (data[0]['community']) {
          setErrors(data);
          setModalErrors(true);
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    selectAll();
  }, []);

  if (!calculations) {
    return null;
  }

  return (
    <>
      <Button color="transparent" className="mr-1" title="clone" onClick={toggle}>
        <Icon name="clone" />
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg" id="modal-copy-calcs">
        <ModalHeader toggle={toggle}>{t('report_multiple_copy_calculations')}</ModalHeader>
        <ModalBody className=" modal-list-cals ">
          <div>
            <ClassicAutocomplete
              resource="Community"
              display="name"
              className="tag"
              tag="name"
              placeholder={t('select_community')}
              autoload
              multiple
              clearable
              value={communitySelected}
              onChange={(opt, options) => {
                setCommunitySelected(options);
              }}
            />
          </div>
          <Table striped className="w-100">
            <thead>
              <tr>
                <th style={{ width: '80px' }}>
                  <Button color="transparent" size="sm" className="py-0" onClick={() => selectAll()}>
                    {!isBtnSelected ? t('dup_calc_select_all') : t('dup_calc_unselect_all')}
                  </Button>
                </th>
                <th style={{ width: '80px' }}>{t('id')}</th>
                <th style={{ width: '400px' }}>{t('name')}</th>
                <th>
                  <Select
                    options={tags}
                    isMulti
                    isClearable
                    value={tagsSelected}
                    className="filter-tags"
                    onChange={filterByTags}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(visibleCalc) &&
                visibleCalc.map((calc) => {
                  return (
                    <tr key={calc.id}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={selected[calc.id]}
                          onChange={(e) => selectItem(calc.id, e.target.checked)}
                        />
                      </td>
                      <td>{calc.id}</td>
                      <td>{calc.alias}</td>
                      <td>{getTag(calc.tags, calc)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => postCalculationsCopies()} disabled={loading}>
            {t('btn_copy_calculations')}
            {loading && <Spinner color="black" size="sm" />}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>

      {/*  
          SECOND MODAL
      */}
      <Modal isOpen={modalErrors} toggle={toggleErrorsModal} size="lg">
        <ModalHeader toggle={toggleErrorsModal}>{t('report_copy_calcs_errors')}</ModalHeader>
        <ModalBody className=" modal-list-cals ">
          <p>{t('copy_errors_founds_inside_community')}</p>
          <div class="list-group">
            {errors &&
              Array.isArray(errors) &&
              errors.map((error) => {
                return (
                  <div class="list-group-item ">
                    <Row>
                      <Col>
                        <p className="fw-bold">{error.communityName}</p>
                        <p>
                          <a target="blank" href={`/reports/${error.report}`}>
                            Report: {error.report}
                          </a>
                        </p>
                      </Col>
                      <Col>
                        <p clssName="fw-bold">{t('existing_calcs')}</p>
                        <ul>
                          {Array.isArray(error.aliases) &&
                            error.aliases.map((alias) => {
                              return <li>{alias}</li>;
                            })}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => postCalculationsCopies(true)} disabled={loading}>
            {t('btn_force_copy_calculations')} {loading && <Spinner color="black" size="sm" />}
          </Button>{' '}
          <Button color="secondary" onClick={toggleErrorsModal}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DuplicateCalculations;
