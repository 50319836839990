import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  type,
  size = 'sm',
  groupTag = FormGroup,
  defaultChecked = false,
  labelTag = Label,
  errorTag = Error,
  inputTag = Input,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta] = useField({ name });
  const { submitCount, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (field.name?.includes('displayLegend')) {
      if (field.value) {
        setFieldValue(field.name, field.value);
      } else {
        setFieldValue(field.name, true);
      }
    }
  }, []);

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} bssize={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      {/* Input */}
      <InputTag
        type={type || 'text'}
        invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
        autoComplete="off"
        bssize={size}
        defaultChecked={true}
        {...field}
        value={field.value || ''}
        checked={field.value || ''}
        {...props}
        {...inputProps}
      />

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
