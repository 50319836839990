import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Button } from 'reactstrap';
import WeverCore from 'wever-core';
import CookieView from 'wever-core/dist/components/security/cookie/view';
import 'wever-core/dist/styles/bootstrap.css';

// Styles
import '@/assets/scss/index.scss';
import AuthLayout from '@/components/layout/auth-layout';
import LoadingLayout from '@/components/layout/loading-layout';
import routes from '@/routes';
import { Provider } from '@/services/context';
import environment from '@/services/environment';
import notifications from '@/services/notifications';
import router from '@/services/router';
import security from '@/services/security';
import translator, { t } from '@/services/translator';
import object from '@/services/utils/object';

import './fontawesome';
import './moment';

// import './userback';

export default class App extends React.Component {
  state = {
    title: '',
    loading: true,
    modal: {
      show: false,
      title: null,
      content: null,
      actions: []
    },
    openModal: this.openModal.bind(this),
    closeModal: this.closeModal.bind(this),
    confirm: this.confirm.bind(this)
  };

  componentDidMount() {
    const locale = object.get('locale', security.user, navigator.language || environment.get('default_locale'));
    translator.setLocale(locale).then(() => {
      this.setState({ loading: false, locale });
    });
  }

  openModal(title, content, actions, size) {
    this.setState({
      modal: { show: true, title, content, actions, size }
    });
  }

  closeModal() {
    this.setState({
      modal: {
        show: false,
        title: null,
        content: null,
        actions: []
      }
    });
  }

  confirm(description, onClick) {
    return this.openModal(
      <h3>{t('do_you_confirm')}</h3>,
      description,
      <Button
        color="default"
        onClick={() => {
          this.closeModal();
          onClick && onClick();
        }}
      >
        {t('confirm')}
      </Button>
    );
  }

  render() {
    if (this.state.loading) {
      return security.isAuthenticated() ? <LoadingLayout /> : <AuthLayout loading />;
    }

    return (
      <Provider value={this.state}>
        {notifications.render()}
        {router.render(routes)}
        <WeverCore>
          {!window.location.pathname.includes('shared') && <CookieView alias="collector_cookie" type="classic" />}
        </WeverCore>
      </Provider>
    );
  }
}
