import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';

import helper from './helper';

export default ({
  name,
  label,
  value,
  onSelect,
  options,
  clearable = false,
  multiple = false,
  isFormik = false,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Select,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta, fieldHelper] = useField({ name });
  const { submitCount, mapping, resource } = useFormikContext();
  const [inputOptions, setInputOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  const invalid = (meta.touched || submitCount > 0) && meta.error !== undefined;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  useEffect(() => {
    if (options && options !== undefined && Array.isArray(options)) {
      setInputOptions(options);
    } else if (mapping) {
      // If not choices, please verify on the server side the validation groups
      setInputOptions(mapping.getChoices(name));
    } else if (resource) {
      setLoading(true);
      resource.fetchOptions(name).then((resourceOptions) => {
        setInputOptions(resourceOptions);
        setLoading(false);
      });
    }
  }, [options, mapping, resource, name]);

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      {/* Input */}
      <InputTag
        name={name}
        placeholder=""
        isMulti={multiple}
        invalid={invalid}
        options={inputOptions}
        isLoading={loading}
        isDisabled={loading}
        isClearable={clearable}
        isSearchable
        onChange={(option) => {
          const realValue = helper.fromChoicesToValues(option, inputOptions, multiple);
          if (name) {
            fieldHelper.setValue(realValue);
          }

          if (onSelect) {
            onSelect(realValue);
          }
        }}
        onBlur={() => fieldHelper.setTouched(true)}
        styles={helper.buildStyles(size, invalid)}
        value={
          typeof value === 'object' ? value : helper.fromValuesToChoices(value || field.value, inputOptions, multiple)
        }
        {...props}
        {...inputProps}
      />

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
