import Resource from '@/services/resources/common';

import api from '../api';

export default class TagResource extends Resource {
  constructor(entrypoint = '/tags', alias = 'Tag') {
    super(entrypoint, alias);
  }

  async loadAll() {
    return api.get(this.entrypoint + '/all').then(({ data }) => data);
  }
}
