import React, { useContext, useEffect, useState } from 'react';

// Tabs
import TabContainer, { TabElement } from '@/components/common/tabs/index';
import ResourceForm from '@/components/resources/form';
// Context
import { InformationSheetContext } from '@/contexts/information-sheet-context';
// Resource
import getResource from '@/services/resources';
// Core
import _Security from '@/services/security';
import { t } from '@/services/translator';

import ActionSheet from './action_sheet';
import Communication from './communication/information-sheet-communication';
import Configuration from './configuration';
import Dashboards from './dashboards';
import Diagnostics from './diagnostics';
import General from './general/information-sheet-general';
import GeneralCommunity from './general_community';
import Managers from './managers';
import PartnerTable from './partner';
import TrackingFilesTab from './tracking-files';

const InformationSheetForm = ({ match }) => {
  const tabInformationSheet = [
    t('communitiesapp_general'),
    t('communitiesapp_communication'),
    t('communitiesapp_community_information_wever'),
    t('communitiesapp_community_partner'),
    t('communitiesapp_community_configuration'),
    t('communitiesapp_community_managers'),
    t('communitiesapp_community_action_sheet'),
    t('communitiesapp_community_dashboards'),
    t('Diagnostics'),
    t('Fichiers de suivi')
  ];
  // Context
  const communityId = useContext(InformationSheetContext);

  const [community, setCommunity] = useState();

  const getCommunityId = async () => {
    const communityResource = await getResource('Community').read(communityId);
    setCommunity(communityResource);
  };

  useEffect(() => {
    getCommunityId();
    // eslint-disable-next-line
  }, []);

  // Fonction pour l'affichage des onglets en fonction du Role du User
  const tabName = () => {
    const list = [];
    if (!_Security.isGranted('ROLE_ADMIN')) {
      for (let i = 0; i < 2; i++) {
        list.push(tabInformationSheet[i]);
      }
      return list;
    } else {
      return tabInformationSheet;
    }
  };

  const initValues = {
    name: '',
    operationName: '',
    information: {
      activitySector: '',
      operationName: ''
    },
    sites: [
      {
        name: '',
        addressInformationSheet: [],
        capacity: ''
      }
    ],
    trackingFiles: {
      summary: ''
    },
    copils: [
      {
        lastname: '',
        firstname: '',
        job: '',
        email: '',
        phone: '',
        access: true
      }
    ],
    dataPrivates: { primaryColor: '', secondaryColor: '' },
    url: '',
    partners: []
  };

  return (
    <ResourceForm resource="Community" id={match.params.id} initialValues={initValues}>
      {({ submitting, values, renderActions }) => (
        <>
          <TabContainer navigation={tabName()}>
            <>
              <TabElement tabId="0">
                <General />
              </TabElement>
              <TabElement tabId="1">
                <Communication />
              </TabElement>
              <TabElement tabId="2">
                <GeneralCommunity />
              </TabElement>
              <TabElement tabId="3">
                <PartnerTable path="partners" name="partners" />
              </TabElement>
              <TabElement tabId="4">
                <Configuration path="configuration" />
              </TabElement>
              <TabElement tabId="5">
                <Managers path="managers" />
              </TabElement>
              <TabElement tabId="6">
                <ActionSheet path="action_sheet" id={communityId} />
              </TabElement>
              <TabElement tabId="7">
                <Dashboards path="dashboards" />
              </TabElement>
              <TabElement tabId="8">
                <Diagnostics path="diagnostics" />
              </TabElement>
              <TabElement tabId="9">
                <TrackingFilesTab path="tracking-files" />
              </TabElement>
            </>
          </TabContainer>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  );
};

export default InformationSheetForm;
