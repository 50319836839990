/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup } from 'reactstrap';

import helper from '@/components/form/select/helper';
import api from '@/services/api';
import Context from '@/services/context';
import Logger from '@/services/logger';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export const Autocomplete = (props) => {
  // props
  const {
    resource,
    display,
    tag,
    size,
    multiple,
    filters,
    clearable,
    value,
    onChange,
    autoload,
    placeholder,
    defaultOption,
    groupProps,
    cachable = true,
    className = 'w-100',
    resourceFunction = 'list'
  } = props;
  const { locale } = useContext(Context);

  const [values, setValues] = useState();
  const [timer, setTimer] = useState();

  /**
   * Format value or received options (from api)
   * used in fetchOptions and useEffect.
   *
   * @param {array} collection
   */
  const formatCollectionToOptions = async (collection, isMultiple, isSettingValue = false) => {
    if (!Array.isArray(collection) && (multiple || isMultiple)) {
      collection = [collection];
    }

    let response = null;
    if (multiple || isMultiple) {
      const objCollection = [];

      if (defaultOption) {
        objCollection.push(defaultOption);
      }

      for (let i = 0; i < collection.length; i++) {
        if (typeof collection[i] === 'object') {
          let entity = collection[i];

          if (!entity[display] && entity['@id']) {
            response = await api.get(entity['@id']);
            entity = response.data;
          }

          let labelToDisplay = entity[display];

          if (typeof labelToDisplay === 'object') {
            labelToDisplay = labelToDisplay[locale];
          }

          objCollection.push({ label: labelToDisplay, value: entity });
        } else {
          response = await api.get(collection[i]);

          if (response.data) {
            let labelToDisplay = response.data;
            if (typeof labelToDisplay === 'object') {
              labelToDisplay = response.data[locale];
            }

            objCollection.push({ label: labelToDisplay, value: response.data });
          }
        }
      }

      if (isSettingValue) {
        setValues(objCollection);
        return;
      }

      return objCollection;
    }

    let obj = collection;

    if (typeof collection !== 'object') {
      response = await api.get(collection);

      if (response.data) {
        obj = response.data;
      }
    }

    let labelToDisplay = obj[display];
    if (typeof labelToDisplay === 'object') {
      labelToDisplay = labelToDisplay[locale];
    }

    if (isSettingValue) {
      setValues({ label: t(labelToDisplay || ''), value: obj });
    }

    return { label: t(labelToDisplay || ''), value: obj };
  };

  /**
   * @param {string} terms
   */
  const fetchOptions = async (terms) => {
    try {
      let filtersList = tag && terms ? { [tag]: terms } : {};

      if (resourceFunction === 'autocompleteOnContent') {
        filtersList = { field: tag, value: terms };
      }

      if (resourceFunction === 'autocompleteOnContent') {
        filtersList = { field: tag, value: terms };
      }

      // on peut rajouter manuellement des choses
      if (filters) {
        filtersList = { ...filtersList, ...filters };
      }

      filtersList.locale = locale;
      const response = await getResource(resource)[resourceFunction](filtersList);

      let allOptions = [...response['hydra:member']];

      allOptions.sort(function (a, b) {
        let aName = a[display || tag];
        let bName = b[display || tag];

        if (typeof aName === 'object') {
          aName = aName[locale] || '';
        }

        if (typeof bName === 'object') {
          bName = bName[locale] || '';
        }

        aName = aName.toLowerCase();
        bName = bName.toLowerCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        }

        return 0;
      });

      return formatCollectionToOptions(allOptions, true);
    } catch (e) {
      Logger.error('Autocomplete', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  /**
   * @param {array} options
   */
  const handleOnChange = (options) => {
    if (!options) {
      return;
    }
    let optionsToValue = [];

    if (multiple) {
      options.forEach((option) => {
        if (option.value) {
          optionsToValue.push(option.value['@id']);
        }
      });
    } else {
      if (options.value) {
        let valueOption = options.value['@id'];
        if (!valueOption && options.value['id']) {
          valueOption = getResource(resource).entrypoint + '/' + options.value['id'];
        }
        optionsToValue = valueOption;
      }
    }
    setValues(options);

    if (onChange && typeof onChange === 'function') {
      onChange(optionsToValue, options);
    }
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    setValues(value);
  }, [value]);

  return (
    <FormGroup {...groupProps} className={className}>
      <AsyncSelect
        isMulti={multiple}
        isClearable={clearable}
        cacheOptions={cachable}
        loadOptions={loadAsyncOptions}
        onChange={handleOnChange}
        value={values}
        styles={helper.buildStyles(size, false)}
        defaultOptions={autoload}
        className="autocomplete-async"
        placeholder={placeholder}
      />
    </FormGroup>
  );
};

Autocomplete.propTypes = {
  size: PropTypes.string,
  resource: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  tag: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  autoload: PropTypes.bool,
  hasLabel: PropTypes.bool
};

Autocomplete.defaultProps = {
  size: 'sm',
  multiple: false,
  clearable: false,
  autoload: false,
  hasLabel: true,
  name: null,
  value: null
};
