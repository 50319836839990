/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col } from 'reactstrap';

import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

import { WysiwygEditor } from '@/components/form';

const TrackingFilesTab = ({ id }) => {
  
  

  return (
    <Card name="tracking-files-container">
        <WysiwygEditor name="trackingFiles.summary" />
    </Card>
  );
};

export default TrackingFilesTab;
