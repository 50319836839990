import { useField, ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Row, Col, Label, CustomInput, Table, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import _Form from '@/components/form';
import _Api from '@/services/api';
import _Security from '@/services/security';
import { t } from '@/services/translator';

import Logo from '../logo';
import CssFiles from './css-style-files';

const { Input } = _Form;

const Communication = () => {
  const [field, , helper] = useField({ name: 'information.visibleMap' });
  const [idField] = useField({ name: 'id' });
  const { submitForm } = useFormikContext();
  const [fieldFiles, , helpersFiles] = useField({ name: 'files' });

  const addMedia = (stringMedia, obj) => {
    const cfiles = fieldFiles.value ? [...fieldFiles.value] : [];
    cfiles.push(obj || stringMedia);
    helpersFiles.setValue(cfiles);
    submitForm();
  };

  const deleteMedia = async (media, index) => {
    try {
      await _Api.delete(`/medias/${media.id}`);
      const cloned = [...fieldFiles.value];
      cloned.splice(index, 1);
      helpersFiles.setValue(cloned);
      submitForm();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Row className="m-0 mb-1">
        <Col className="col-12 p-2 px-5">
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_operation_name')} :</Label>
            <Col sm={3}>
              <Input label={null} name="information.operationName" />
              <ErrorMessage name="information.operationName">
                {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
              </ErrorMessage>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_operation_name_url')} :</Label>
            <Col sm={3}>
              <Input label={null} name="operationName" />
              <ErrorMessage name="operationName">
                {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
              </ErrorMessage>
            </Col>
          </FormGroup>
          <p className="informationsheetOperationinformation">*{t('communitiesapp_operation_information')}</p>
          <span></span>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_vector_logo')} (.eps/.ai/.psd/.svg) :</Label>
            <Col sm={3}>
              <Logo name="logoVector" resource="communities" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_no_vector_logo')} :</Label>
            <Col sm={3}>
              <Logo name="logo" resource="communities" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_graphic_chart')} :</Label>
            <Col sm={3}>
              <Logo name="graphChart" resource="communities" />
            </Col>
          </FormGroup>

          {!_Security.isGranted('ROLE_ADMIN') ? null : (
            <>
              <FormGroup row>
                <Label sm={3}>{t('communitiesapp_admin_information')} :</Label>
                <Col sm={3}>
                  <Input type="textarea" label={null} name="information.adminInformation" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>{t('communitiesapp_visible_map')} :</Label>
                <Col sm={3}>
                  <CustomInput
                    type="switch"
                    id="exampleCustomSwitch"
                    name="information.visibleMap"
                    checked={Boolean(field.value)}
                    onChange={(e) => {
                      helper.setValue(e.target.checked);
                    }}
                  />
                </Col>
              </FormGroup>
            </>
          )}

          <div>
            {t('communityapp_add_media_automatically_save')}
            <Logo name="logo-1" resource="communities" onChange={addMedia} subFileSaving={`/css/${idField.value}`} />
          </div>
          <Table>
            <thead>
              <tr>
                <th>{t('communitiesapp_info')}</th>
                <th>{t('communitiesapp_files')}</th>
                <th>{t('communitiesapp_delete_cannot_be_canceled')}</th>
              </tr>
            </thead>
            <tbody>
              {fieldFiles.value &&
                fieldFiles.value.map((fieldFile, index) => (
                  <tr>
                    <td>
                      <div>
                        <a target="__blank" href={fieldFile.path}>
                          {fieldFile.path}
                        </a>
                      </div>
                      <div>{fieldFile.originalName}</div>
                    </td>
                    <td>
                      <Logo name={`files[index]`} resource="communities" subFileSaving={`/css/${idField.id}`} />
                    </td>
                    <td>
                      <Button color="danger" className="wever-outline" onClick={() => deleteMedia(fieldFile, index)}>
                        <Icon name="trash" />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
        <Col className="col-12 bg-light border-top">
          <CssFiles community={idField.value} />
        </Col>
      </Row>
    </>
  );
};

export default Communication;
