const icons = require('@fortawesome/free-solid-svg-icons');

const IconList = [
  icons.faChevronLeft,
  icons.faChevronRight,
  icons.faChevronUp,
  icons.faChevronDown,
  icons.faSearch,
  icons.faCircle,
  icons.faEllipsisV,
  icons.faEllipsisH,
  icons.faUserEdit,
  icons.faTrash,
  icons.faTrashAlt,
  icons.faUserNinja,
  icons.faPen,
  icons.faPenAlt,
  icons.faPlus,
  icons.faPlusCircle,
  icons.faPlusSquare,
  icons.faFilter,
  icons.faSort,
  icons.faEraser,
  icons.faCheck,
  icons.faUndo,
  icons.faInfoCircle,
  icons.faTimes,
  icons.faTimesCircle,
  icons.faEye,
  icons.faEyeSlash,
  icons.faPlay,
  icons.faCogs,
  icons.faCar,
  icons.faCarSide,
  icons.faMotorcycle,
  icons.faBiking,
  icons.faWalking,
  icons.faBus,
  icons.faRoad,
  icons.faLocationArrow,
  icons.faGlobeEurope,
  icons.faRecycle,
  icons.faHome,
  icons.faPeopleArrows,
  icons.faFileContract,
  icons.faClone,
  icons.faCog,
  icons.faObjectGroup,
  icons.faPalette,
  icons.faBalanceScale,
  icons.faQuestionCircle,
  icons.faQuestion,
  icons.faFileAlt,
  icons.faDirections,
  icons.faHandshake,
  icons.faBuilding,
  icons.faServer,
  icons.faEnvelope,
  icons.faLock,
  icons.faListOl,
  icons.faGripVertical,
  icons.faBook,
  icons.faBookmark,
  icons.faCalculator,
  icons.faLanguage,
  icons.faDraftingCompass,
  icons.faPause,
  icons.faPauseCircle,
  icons.faPlayCircle,
  icons.faPlay,
  icons.faCheckCircle,
  icons.faTimesCircle,
  icons.faRestroom,
  icons.faExclamation,
  icons.faChartArea,
  icons.faPager,
  icons.faFileAlt,
  icons.faChartPie,
  icons.faTable,
  icons.faBorderAll,
  icons.faBorderStyle,
  icons.faTimes,
  icons.faDownload,
  icons.faSync,
  icons.faCopy,
  icons.faClone,
  icons.faInfoCircle,
  icons.faTrain,
  icons.faSubway,
  icons.faPlane,
  icons.faFileInvoice,
  icons.faFileAlt,
  icons.faPaste,
  icons.faCloudUploadAlt,
  icons.faPaperPlane,
  icons.faArrowDown,
  icons.faArrowUp,
  icons.faCalendarAlt,
  icons.faTags,
  icons.faNetworkWired
];

/**
 * Add all icons used in project.
 */
require('@fortawesome/fontawesome-svg-core').library.add(IconList);

export default IconList;
