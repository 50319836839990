import { Formik } from 'formik';
import React, { Component } from 'react';
import { Container, Spinner, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import Context from '@/services/context';
import logger from '@/services/logger';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import router from '@/services/router';
import { t } from '@/services/translator';
import object from '@/services/utils/object';
import { NotFound } from '@/views/errors';

import FormResource from './form/resource-form-content';

export default class Form extends Component {
  static contextType = Context;

  state = {
    loading: true,
    submitting: false,
    data: {}
  };

  /**
   * Get method to use for api calls
   * Depend of context
   *
   * @return {string}
   */
  get method() {
    return this.id !== undefined ? 'put' : 'post';
  }

  /**
   * Get crud method name
   *
   * @return {string}
   */
  get crudMethod() {
    return this.method === 'put' ? 'update' : 'create';
  }

  /**
   * Get current id
   *
   * @return {number|string}
   */
  get id() {
    return this.props.id;
  }

  constructor(props) {
    super(props);
    this.state.data = props.initialValues || {};
    this.resource = getResource(props.resource);
    this.submit = this.submit.bind(this);
  }

  /**
   * Initialize form context (validation & existing data)
   */
  componentDidMount() {
    try {
      // Get mapping to build validation rules
      this.resource.fetchMapping(this.crudMethod, this.method, this.id).then(() => this.fetch());
    } catch (e) {
      notifications.error(t('error'), t('An error Occurred'));
    }
  }

  /**
   * Fetch data in api for update actions
   *
   * @return {Promise}
   */
  fetch(formik) {
    const resource = this.resource;
    const { initialValues } = this.props;

    // Create context
    if (this.id === undefined) {
      this.setState({
        loading: false,
        data: object.merge(resource.getMapping(this.crudMethod).getDefaultValues(), initialValues || {})
      });

      return Promise.resolve();
    }

    // Update context
    return this.resource
      .read(this.id)
      .then((data) => {
        this.setState(
          {
            loading: false,
            data: object.merge(initialValues || {}, data),
            access: true
          },
          () => {
            if (formik) {
              formik.setValues({ ...formik.values, ...data });
            }
          }
        );
      })
      .catch(() => {
        this.setState({ loading: false, access: false });
      });
  }

  /**
   * Submit form values to api
   * If errors are returned, they will be set in formik context
   *
   * @param {object} values
   * @param {object} formik
   */
  submit(values, formik) {
    // Permet d'avoir une validation custom en dehors de yup, formik.
    console.info('pass here FIRST');
    if (typeof this.props.customValidation === 'function' && !this.props.customValidation(values, formik)) {
      console.log('custom');
      return;
    }

    this.setState({ submitting: true }, () => {
      // Submit form with method expected
      if (!this.resource[this.crudMethod]) {
        logger.warn('FORM SUBMIT', 'Resource has no method', { r: this.resource, m: this.crudMethod });
        return;
      }

      if (typeof values === 'object') {
        Object.keys(values).forEach((key) => {
          if (null === values[key]) {
            delete values[key];
          }
        });
      }
      console.info('pass here', this.resource, this.crudMethod, values);
      this.resource[this.crudMethod](values)
        .then((data) => {
          this.setState({ submitting: false, data }, () => {
            notifications.success(t('saved'), t('form_saved'));
            formik.setValues({ ...values, ...data });

            const { onSuccess } = this.props;
            if (typeof onSuccess === 'function') {
              return onSuccess(data);
            }

            if (this.id === undefined) {
              if (this.resource.canUpdate()) {
                router.navigateToResource(this.resource.alias, 'update', { id: data.id });
              }
            }
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 400) {
            this.handleViolations(response.data.violations || [], formik);
          }
          logger.error('FORM', 'CANNOT SUBMIT', { response });
          this.setState({ submitting: false });
        });
    });
  }

  /**
   * Set form error with violations returned from api
   *
   * @param {object[]} violations
   * @param {object}   formik
   */
  handleViolations(violations = [], formik) {
    notifications.error(t('oops'), t('form_has_error'));

    for (let i = 0, len = violations.length; i < len; ++i) {
      console.log('violations ', violations[i].propertyPath, violations[i].message);
      formik.setFieldError(violations[i].propertyPath, violations[i].message);
    }
  }

  /**
   * Run deletion of item
   */
  handleDelete() {
    this.context.confirm(
      <div>
        {t('confirm_delete')}: {this.id}
      </div>,
      () => {
        this.setState({ loading: true }, () => {
          this.resource.delete(this.id).then(() => {
            router.navigateToResource(this.resource.alias, 'read:list');
          });
        });
      }
    );
  }

  /**
   * Render submit button
   *
   * @return {JSX.Element}
   */
  renderSubmit() {
    const { submitting } = this.state;

    return (
      <Button type="submit" color="info" size="md" disabled={submitting}>
        {submitting ? <Spinner size="sm" className="mr-2" /> : <Icon name="check" className="mr-2" />}
        {t('submit')}
      </Button>
    );
  }

  navigateToList() {
    if (!this.resource.navigateToList()) {
      router.navigateToResource(this.resource.alias, 'read:list');
    }
  }

  /**
   * Render submit button
   *
   * @return {JSX.Element}
   */
  renderBackToList() {
    if (!this.resource.canReadList()) {
      return null;
    }

    const onClick = () => this.navigateToList();

    return (
      <Button size="md" color="light" onClick={onClick}>
        <Icon name="chevron-left" className="mr-2" />
        {t('back_to_list')}
      </Button>
    );
  }

  /**
   * Build form action bar
   *
   * @param {?JSX.Element} actions
   *
   * @return {JSX.Element}
   */
  renderActions(actions) {
    return (
      <>
        {this.renderSubmit()}
        {actions}
        {this.id && this.resource.canDelete() && (
          <Button size="md" color="danger" disabled={this.state.submitting} onClick={() => this.handleDelete()}>
            <Icon name="trash-alt" className="mr-2" /> {t('delete')}
          </Button>
        )}
        {this.resource.canReadList() && this.renderBackToList()}
      </>
    );
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    if (this.state.loading) {
      return (
        <Container className="text-center mt-4" fluid>
          <Spinner style={{ width: '4rem', height: '4rem' }} color="secondary" />
        </Container>
      );
    }

    const { submitting, data, access } = this.state;
    let { children, validate } = this.props;
    children = Array.isArray(children) ? children : [children];

    // First child has to be the form content
    // Other child will be append after form
    const [FormContent, ...subChildren] = children;
    const mapping = this.resource.getMapping(this.crudMethod);

    if (false === access) {
      return <NotFound />;
    }

    return (
      <Formik initialValues={data} onSubmit={this.submit} validationSchema={mapping.rules} validate={validate}>
        {(formik) => (
          <FormResource
            mapping={mapping}
            resource={this.resource}
            formContent={FormContent}
            formik={formik}
            subChildren={subChildren}
            submitting={submitting}
            setSubmitting={(submitting) => this.setState({ submitting })}
            fetch={this.fetch.bind(this)}
            renderSubmit={this.renderSubmit.bind(this)}
            renderBackToList={this.renderBackToList.bind(this)}
            renderActions={this.renderActions.bind(this)}
          />
        )}
      </Formik>
    );
  }
}
