import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Loader from '@/components/common/loader';
import { Autocomplete, Input, TableLangValue, Select } from '@/components/form';
import TagAutocomplete from '@/components/form/tags';
import Api from '@/services/api';
import { t } from '@/services/translator';

import Filters from './filters/filters';
import IndexV2 from './v2';
import MapDashboard from './v2/map-dashboard';
import TextV2 from './v2/text';

const Index = ({ submitting, id }) => {
  const [typeField] = useField({ name: 'type' });
  const [versionField] = useField({ name: 'version' });
  const [dataTypeOptions, setDataTypeOptions] = useState();
  const [dataTypes, setDataTypes] = useState();
  const [stringFields, setStringFields] = useState([]);
  const [filterTypes, setFilterTypes] = useState();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dashboardType, setDashboardType] = useState(true);

  const getDataReference = async () => {
    try {
      const responseFields = await Api.get('/dashboards/data_types');
      const responseFilters = await Api.get('/dashboards/filters');
      const responseAliases = await Api.get('/reports/fields');

      if (responseFields.data) {
        setDataTypeOptions(Object.keys(responseFields.data).map((key) => ({ value: key, label: t(key) })));
        setDataTypes(responseFields.data);
      }

      if (responseFilters.data) {
        setFilterTypes(Object.keys(responseFilters.data).map((key) => ({ value: key, label: t(key) })));
      }

      if (responseAliases.data) {
        const buildingAliases = responseAliases.data.map((alias) => ({
          value: alias,
          label: (
            <div>
              ({alias}) - {t(alias)}
            </div>
          )
        }));

        setStringFields(responseAliases.data);
        setFields(buildingAliases);
      }
    } catch (e) {
      // L'erreur n'empêche pas l'utilisation, les appels API sont censé aider
      console.error(e);
    }

    setLoading(false);
  };

  const isMapOrDashboard = () => {
    return (
      typeField.value?.name === 'map' ||
      typeField.value?.name === 'dashboard' ||
      dashboardType === 'map' ||
      dashboardType === 'dashboard'
    );
  };

  useEffect(() => {
    getDataReference();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Card>
      <CardBody className={submitting ? 'loading' : ''}>
        <Row>
          <Col lg={12}>
            <TableLangValue name="name" placeholder="" pattern="/^[a-zA-Z_0-9]+$" />
          </Col>

          <Col lg={3}>
            <Select
              name="version"
              options={[
                { value: 1, label: t('adminapp_version_1') },
                { value: 2, label: t('adminapp_version_2') }
              ]}
            />
          </Col>
          <Col lg={3}>
            <Autocomplete
              name={`reportInstanceType`}
              resource="ReportInstanceType"
              display="name"
              tag="name"
              autoload
            />
          </Col>
          <Col lg={3}>
            <Autocomplete
              name="type"
              clearable
              resource="DashboardLayoutType"
              autoload
              display="name"
              tag="name"
              onChange={(val, options) => setDashboardType(options.value.name ?? options.value)}
            />
          </Col>
          <Col lg={3}>
            <Input name="displayName" type="checkbox" />
          </Col>
          <TagAutocomplete name="tags" multiple />
        </Row>
        <Row>
          <Col lg="12">
            <Filters types={filterTypes} fields={fields} />
          </Col>
          <Col lg="12">
            {isMapOrDashboard() ? (
              <MapDashboard dataTypes={dataTypes} dataTypeOptions={dataTypeOptions} dashboardType={dashboardType} />
            ) : dashboardType === 'text' || typeField.value?.name === 'text' ? (
              <TextV2 dataTypes={dataTypes} dataTypeOptions={dataTypeOptions} />
            ) : (
              <IndexV2
                filterTypes={filterTypes}
                fields={fields}
                setFields={setFields}
                dataTypes={dataTypes}
                dataTypeOptions={dataTypeOptions}
                stringFields={stringFields}
                setStringFields={setStringFields}
                dashboardType={dashboardType || typeField.value?.name}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
