import Resource from '@/services/resources/common';

import api from '../api';

export default class TypeClientResource extends Resource {
  constructor(entrypoint = '/type_clients', alias = 'TypeClient') {
    super(entrypoint, alias);
  }

  async readList() {
    const responses = await api.get(this.entrypoint);
    return responses.data;
  }
}
