import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Autocomplete, TableLangValue, Input, Editor, TagsAutocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const Index = ({ match }) => {
  return (
    <View>
      <ResourceForm resource="GeometryPolygon" id={match.params.id} initialValues={{ name: {} }}>
        {({ submitting, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={12}>
                    <TableLangValue name="name" />
                  </Col>
                  <Col lg={6}>
                    <Input name="alias" placeholder="ex: my_alias" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>
                  <Col lg={12}>
                    <Input name="description" type="textarea" />
                  </Col>
                  <Col lg={12}>
                    <Editor name="data" height="300" returnType="array" />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="type"
                      clearable
                      resource="GeometryType"
                      display="name"
                      tag="name"
                      autoload
                      resourceFunction="autocompleteOnContent"
                    />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="group"
                      clearable
                      resource="GeometryGroup"
                      display="name"
                      tag="name"
                      autoload
                      resourceFunction="autocompleteOnContent"
                    />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="parent"
                      clearable
                      resource="GeometryPolygon"
                      display="alias"
                      tag="alias"
                      autoload
                    />
                  </Col>
                  <Col lg="6">
                    <TagsAutocomplete name="tags" multiple />
                  </Col>
                  <Col lg={2}>
                    <Input type="color" id="colorPicker" name="color" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
